export const mockProps = {
  name: 'logo-cou',
  title: 'Icon description',
  size: 'lg',
  color: 'red',
  customSize: '15rem',
  width: '',
  height: '',
  isCustomColor: false,
  isCustomSize: true,
};