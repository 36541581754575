
import { defineComponent, reactive } from 'vue';
import Icon from './Icon.vue';
import InputSelect from '@/components/Input/Select/InputSelect.vue';
import InputText from '@/components/Input/Text/InputText.vue';
import InputCheckbox from '@/components/Input/Checkbox/InputCheckbox.vue';
import { mockProps } from './mockup';
import { icons } from '@/models/components/icon.model';
import { sizesX } from '@/models/components/size.model';

export default defineComponent({
  name: 'SampleIcon',
  components: {
    Icon,
    InputSelect,
    InputText,
    InputCheckbox,
  },
  setup() {
    return {
      props: reactive(mockProps),
      icons,
      sizesX,
    };
  },
});
